<div
    class="container"
    fxFlex
    fxLayout="column"
    fxLayout.gt-sm="row wrap"
    fxLayoutAlign="start center"
    fxLayoutGap="10px">

    <div class="title-row">
      <h1 class="mat-h1 bold"><fa-icon class="pr-1" [icon]="titleIcon" aria-hidden="true"></fa-icon> {{titleLabel}}</h1>
    </div>

    <div fxShow="false" fxShow.gt-sm class="flex-row-break"></div>

    <mat-card class="mat-elevation-z0" fxFlex="1 1 0" fxLayout="column">
      <mat-card-content>
        <div>
          <p>MyPay è il servizio messo a disposizione dalla Regione Calabria e integrato con il Nodo nazionale dei pagamenti pagoPA.</p>
          <p><a href="https://www.pagopa.it" target="_blank">PagoPA</a> è un sistema di pagamenti elettronici realizzato da PagoPA S.p.A. per rendere più semplice, 
            sicuro e trasparente qualsiasi pagamento verso la Pubblica Amministrazione, in attuazione dell'art. 5 del CAD 
            (Codice dell'Amministrazione Digitale) D.Lgs. n. 82/2005.</p>
            
          <p>Su MyPay hai la possibilità di fare pagamenti verso le pubbliche amministrazioni e altri soggetti che hanno aderito all'iniziativa di Regione Calabria:</p>
            <ul>
              <li>puoi cercare e pagare un <a routerLink="/avvisi">avviso di pagamento</a>;</li>
              <li>puoi scegliere un ente e il tipo di <a routerLink="/spontaneo">pagamento spontaneo</a> da effettuare, compilando i campi richiesti e procedendo con il pagamento.</li>
            </ul>
          <p>Se non trovi l'ente o il pagamento di tuo interesse nell'elenco, rivolgiti direttamente all'ente.</p>
        </div>
      </mat-card-content>
    </mat-card>

</div>