import { ValidatorFn } from '@angular/forms';

export class SearchFilter {
  constructor (
    public field: string,
    public label: string,
    public value: string,
    public removable: boolean = true,
  ) {};
}

export class SearchFilterDef {
  constructor (
    public field: string,
    public label: string,
    public value: any,
    public validators: ValidatorFn[],
    public valueConv?: ((value:any, formValues?: any)=>string),
    public removable?: boolean,
  ) {}
}
