import { isBlankOrNull } from 'projects/mypay4-fe-common/src/public-api';

const disabilitaLogging: boolean = true;
const globalAbilitaLog: boolean = true;
const prefissoLogDefault: string = "manage-logging.ts"

function setPrefissoLogDefaultSeBlankOrNull(prefissoLog: string): string
{
  if ( isBlankOrNull( prefissoLog ) ) return prefissoLogDefault;
  return prefissoLog;
}

function isLoggingAbilitato(localAbilitaLog: boolean): boolean
{
  return ( !disabilitaLogging && ( localAbilitaLog || globalAbilitaLog ) );
}

function log(localAbilitaLog: boolean, string: string): void
{
  if ( isLoggingAbilitato( localAbilitaLog ) ) console.log( string ); 
}

export function logInizio(prefissoLog: string, localAbilitaLog: boolean): void
{
  log( localAbilitaLog, setPrefissoLogDefaultSeBlankOrNull( prefissoLog ) + "<INIZIO>" );
}

export function logFine(prefissoLog: string, localAbilitaLog: boolean): void
{
  log( localAbilitaLog, setPrefissoLogDefaultSeBlankOrNull( prefissoLog ) + "<FINE>" ); 
}

export function logValore(prefissoLog: string, localAbilitaLog: boolean, nome: string, valore: any): void
{
  log( localAbilitaLog, setPrefissoLogDefaultSeBlankOrNull( prefissoLog ) + nome + " = " + valore );
}

export function logMessaggio(prefissoLog: string, localAbilitaLog: boolean, testo: string): void
{
  log( localAbilitaLog, setPrefissoLogDefaultSeBlankOrNull( prefissoLog ) + testo );
}