<div
    class="container"
    fxFlex
    fxLayout="column"
    fxLayout.gt-sm="row wrap"
    fxLayoutAlign="start center"
    fxLayoutGap="10px">

    <div class="title-row">
      <h1 class="mat-h1 bold"><fa-icon class="pr-1" [icon]="titleIcon" aria-hidden="true"></fa-icon> {{titleLabel}}</h1>
    </div>

    <div fxShow="false" fxShow.gt-sm class="flex-row-break"></div>

    <mat-card class="mat-elevation-z0" fxFlex="1 1 0" fxLayout="column">
      <mat-card-content>
        <div>
          <!--
          <p>Il primo passo da fare per l'Ente Creditore è aderire a PagoPa, richiedendo le credenziali di accesso al Portale delle Adesioni (PdA) scrivendo una email all’indirizzo <a href="mailto:credenziali@assistenza.pagopa.it" target="_top" title="E-mail">credenziali@assistenza.pagopa.it</a></p>
          <p>Ogni Ente dovrà indicare il proprio Referente dei Pagamenti, al quale saranno inviate tramite PEC le credenziali nominali di accesso. Con le credenziali ottenute, occorre accedere <a href="https://portal.pagopa.gov.it/pda-portal/admin/login" target="_blank" title="Portale delle Adesioni">Portale delle Adesioni</a>, compilare i dati dell’Ente e quindi configurare una “Connessione intermediata”, scegliendo come INTERMEDIARIO TECNOLOGICO la Regione Calabria. Tutti i passi che dovrà compiere il Referente dei Pagamenti per l’adesione sono descritti nell’allegato Manuale Utente <a href="https://portal.pagopa.gov.it/pda-portal/admin/login" target="_blank" title="Portale delle Adesioni">Portale delle Adesioni</a>.</p>
          -->
          
          <p>Il primo passo da fare per l'Ente Creditore è aderire a PagoPa tramite Il nuovo portale Back Office, accessibile tramite SPID da <a href="https://selfcare.pagopa.it/auth/login?onSuccess=%2Fonboarding%2Fprod-pagopa" target="_blank" title="Area Riservata">Area Riservata</a>, compilando i dati dell’Ente e quindi configurando una “Connessione intermediata”, scegliendo come INTERMEDIARIO TECNOLOGICO la Regione Calabria. Tutta la documentazione relativa al nuovo Portale è disponibile al seguente <a href="https://docs.pagopa.it/home/tutti-i-prodotti/pagopa" target="_blank" title="Documentazione PagoPa">link</a>.</p>


          
          <p>A valle di tale adempimento, per aderire alla piattaforma MyPay della Regione Calabria, l’Ente Creditore deve trasmettere a mezzo PEC ad <a href="mailto:agendadigitale.presidenza@pec.regione.calabria.it" target="_top" title="E-mail">agendadigitale.presidenza@pec.regione.calabria.it</a>, la richiesta di adesione alla piattaforma MyPay compilando e sottoscrivendo il seguente modello <a [routerLink]="" (click)="downloadFile( nomeFileRicAdeMyPayCalabria )">Richiesta Adesione</a> nel quale è possibile richiedere esclusivamente i dovuti standard riportati nel catalogo <a [routerLink]="" (click)="downloadFile( nomeFileElencoDovutiMyPay )">"Elenco_Dovuti_MyPay.pdf"</a>.</p>
          <p>Successivamente all’adesione alla piattaforma, sarà possibile richiedere ulteriori dovuti personalizzati per far fronte a particolari necessità dell’Ente Creditore, compilando e sottoscrivendo il seguente <a [routerLink]="" (click)="downloadFile( nomeFileRicAdeMyPayCalabriaPers )">modello</a> per ulteriori dovuti personalizzati. In qualsiasi momento sarà possibile richiedere ulteriori dovuti standard compilando e sottoscrivendo il seguente <a [routerLink]="" (click)="downloadFile( nomeFileRicAttUltDovMyPayCalabriaEnti )">modello</a> per ulteriori dovuti standard.</p>
          <p>Per ogni necessità di chiarimento, supporto e affiancamento agli Enti Creditori nell’espletamento delle attività indicate contattare:</p>
          <ul>
            <li>Assistenza: 081/18329021</li>
            <li>Email: <a href="mailto:segnalazioni-mypaycalabria@eslabs.eng.it" target="_top" title="E-mail">segnalazioni-mypaycalabria@eslabs.eng.it</a></li>
          </ul>
          <p>Per ogni necessità di chiarimento e supporto al cittadino, nell’utilizzo della piattaforma, è possibile scrivere a:</p>
          <ul>
            <li>Email: <a href="mailto:mypay@regione.calabria.it" target="_top" title="E-mail">mypay@regione.calabria.it</a> (NON PEC)</li>
            <li>oppure chiamare al numero: 0961 856248 - attivo dal lunedi al venerdi dalle ore 9:00 alle ore 18:00</li>
          </ul>
        </div>
      </mat-card-content>
    </mat-card>

</div>