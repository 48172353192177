
/**
 * @author RB20231106: Parametrizzazione Download documenti
 *
 */

import { Component, OnInit } from '@angular/core';
import { faInfo } from '@fortawesome/free-solid-svg-icons';
import { FileSaverService } from 'ngx-filesaver';
import { ToastrService } from 'ngx-toastr';
import { ApiInvokerService, ConfigurationService, environment, manageError } from 'projects/mypay4-fe-common/src/public-api';
import { HttpParams } from '@angular/common/http';

@Component({
  selector: 'app-adesione',
  templateUrl: './adesione.component.html',
  styleUrls: ['./adesione.component.scss']
})
export class AdesioneComponent implements OnInit {

  get titleLabel(){ return "Adesione alla piattaforma MyPay" }
  get titleIcon(){ return faInfo }
  private baseApiUrl: string;
  nomeFileElencoDovutiMyPay : string = "Elenco_Dovuti_MyPay.pdf";
  nomeFileRicAdeMyPayCalabria : string = "Richiesta_Adesione_MyPay_Calabria.doc";
  nomeFileRicAdeMyPayCalabriaPers : string = "Richiesta_Adesione_MyPay_Calabria_Personalizzato.doc";
  nomeFileRicAttUltDovMyPayCalabriaEnti : string = "Richiesta_Attivazione_Ulteriori_Dovuti_Standard_MyPay_Calabria_Enti.doc";

  constructor(
    private apiInvokerService: ApiInvokerService,
    private toastrService: ToastrService,
    private fileSaverService: FileSaverService,
    private conf: ConfigurationService
  )  {
    this.baseApiUrl = conf.getProperty('baseApiUrl', environment);
  }

  ngOnInit(): void {
  }

  downloadFile(nomeFile: string) {
    this.downloadDocumento(nomeFile).subscribe(response => {
      const contentDisposition = response.headers.get('content-disposition');
      const fileName = ApiInvokerService.extractFilenameFromContentDisposition(contentDisposition) ?? nomeFile.replace(/^.*[\\\/]/, '');
      const contentType = response.headers.get('content-type') ?? 'application/octet-stream';
      const blob:any = new Blob([response.body], { type: contentType });
      this.fileSaverService.save(blob, fileName);
    }, manageError('Errore durante il download del file', this.toastrService) );
  }
  
  private downloadDocumento(nomeFile: string): any {
    let params = new HttpParams().append( 'filename', nomeFile );
    return this.apiInvokerService.get<any>( this.baseApiUrl + 'public/mybox/download/document/', {params: params,observe: 'response',responseType: 'blob'} );
  }

}