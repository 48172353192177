<div
   class="container"
   fxFlex
   fxLayout="column"
   fxLayout.gt-sm="row wrap"
   fxLayoutAlign="start center"
   fxLayoutGap="10px">
   <div class="title-row">
      <h1 class="mat-h1 bold">
         <fa-icon class="pr-1" [icon]="titleIcon" aria-hidden="true"></fa-icon>
         {{titleLabel}}
      </h1>
   </div>
   <div fxShow="false" fxShow.gt-sm class="flex-row-break"></div>
   <mat-card class="mat-elevation-z0" fxFlex="1 1 0" fxLayout="column">
      <mat-card-content>
         <h1>
            <p class="text-center">INFORMATIVA AI SENSI DELL’ART. 13 DEL REGOLAMENTO UE 2016/679 RELATIVA AL TRATTAMENTO DEI DATI PERSONALI DEGLI UTENTI CHE CONSULTANO IL PORTALE DEI PAGAMENTI ELETTRONICI DELLA REGIONE CALABRIA</p>
         </h1>
         <br><br>
         <h2>PREMESSA</h2>
         <div>
            <p>
               Con le seguenti informazioni desideriamo offrire una visione chiara e trasparente delle modalità di trattamento dei dati personali degli utenti che
               consultano il portale dei pagamenti elettronici della Regione Calabria, accessibile per via telematica all’indirizzo https://pagopa.regione.calabria.it/pa. 
               <br><br>
               Si precisa che tali informazioni non riguardano eventuali altri siti, pagine o servizi online che, seppur raggiungibili tramite collegamenti ipertestuali pubblicati all’interno del presente sito, si riferiscono a risorse esterne a quest’ultimo.
               <br><br>
               Il trattamento dei dati personali raccolti durante la navigazione del sito avverrà nel rispetto del Regolamento Generale sulla Protezione dei Dati Personali 2016/679 (di seguito “GDPR”) che trova piena applicazione in tutti gli Stati membri dell’Unione europea dal 25 maggio 2018.
               <br><br>
               I dati raccolti mediante il Portale Pagamenti sono trattati in formato aggregato, per finalità statistiche.
               <br><br>
               MyPay è il servizio messo a disposizione dalla Regione Calabria e integrato con il Nodo nazionale dei pagamenti SPC (denominato "pagoPA"). PagoPA è un sistema di pagamenti elettronici realizzato dall'Agenzia per L'Italia Digitale (AgID), per rendere più semplice, sicuro e trasparente qualsiasi pagamento verso la Pubblica Amministrazione, in attuazione dell'art. 5 del CAD (Codice dell'Amministrazione Digitale) che stabilisce che le Pubbliche Amministrazioni sono tenute ad accettare pagamenti in formato elettronico a prescindere dall'importo.
            </p>
         </div>
         <br>
         <h2>TITOLARE DEL TRATTAMENTO</h2>
         <div>
            <h3>Regione Calabria</h3>
            <p>
               Titolare del trattamento è l’ente Regione Calabria, con sede legale in Catanzaro c/o Cittadella Regionale, Viale Europa, Località Germaneto 88100, numero verde 800 84 12 89, C.F./P.IVA 02205340793.
               <br>
               Riguardo al trattamento tecnico dei dati personali, funzionale a permettere la navigazione del sito, la fruizione telematica dei servizi disponibili e la produzione di statistiche generali di navigazione, il delegato al trattamento, ai sensi della deliberazione di Giunta regionale n. 29 del 1 febbraio 2021, è il dirigente del Settore “Integrazioni e Sviluppo Sistemi Informativi Regionali” del Dipartimento “Transizione Digitale ed Attività Strategiche”, indirizzo PEC agendadigitale.presidenza@pec.regione.calabria.it.
               <br>
               Riguardo ad ulteriori trattamenti connessi all’erogazione di determinati servizi del sito, fare riferimento ai delegati del titolare indicati nelle rispettive informative specifiche, progressivamente rese disponibili o direttamente visualizzate in corrispondenza delle pagine di accesso ai suddetti servizi.
               <br>
            </p>
            <p> I delegati al trattamento di tali dati sono: </p>
            <ul>
               <li>I settori del dipartimento Economia e finanze (https://www.regione.calabria.it/website/organizzazione/dipartimento4/);</li>
               <li>I settori della Regione preposti alla gestione dell'accertamento delle entrate competenti per materia;</li>
               <li>Il dirigente del Settore "Integrazioni e Sviluppo Sistemi Informativi Regionali" del Dipartimento "Transizione Digitale ed Attivitá Strategiche", per la parte tecnica.</li>
            </ul>
            <br>
            <p>
               Il Titolare ha nominato quale Responsabile esterno del trattamento dei dati l’RTI titolare del contratto Esir-Plus.
               <br>
               Vengono a conoscenza dei dati esclusivamente i soggetti incaricati dal Titolare, autorizzati e istruiti in tal senso, o imprese espressamente nominate come responsabili del trattamento.
            </p>
            <h3>TIPOLOGIE DI DATI TRATTATI E FINALITA' DEL TRATTAMENTO</h3>
            <p>
               La Regione Calabria provvede al trattamento dei dati personali di coloro che interagiscono con il servizio PagoPA May PAY Portale Pagamenti della Regione Calabria accessibile per via telematica all'indirizzo seguente: https://pagopa.regione.calabria.it/pa.
               <br>
               I dati personali oggetto di trattamento esclusivamente per le finalità indicate nel paragrafo precedente:  
               <br>
            </p>
            <ul>
               <li>a) Dati anagrafici, personali e di contatto (tra cui Nome, Cognome, Luogo e Data di nascita, Codice Fiscale, Residenza, Posta elettronica, Telefono);
               </li>
               <li>b) Dati di navigazione. I sistemi informatici e le procedure software preposte al funzionamento dei prodotti/piattaforme messi a disposizione acquisiscono, nel corso del loro normale esercizio, alcuni dati personali la cui trasmissione è implicita nell'uso dei protocolli di comunicazione di Internet. In questa categoria di dati rientrano gli indirizzi IP o i nomi a dominio dei computer e dei terminali utilizzati dagli utenti, gli indirizzi in notazione URI/URL (Uniform Resource Identifier/Locator) delle risorse richieste, l'orario della richiesta, il metodo utilizzato nel sottoporre la richiesta al server, la dimensione del file ottenuto in risposta, il codice numerico indicante lo stato della risposta data dal server (buon fine, errore, ecc.) ed altri parametri relativi al sistema operativo e all'ambiente informatico dell'utente. Tali dati, necessari per la fruizione dei servizi web, sono trattati anche allo scopo di ottenere informazioni statistiche sull'uso dei servizi (pagine più visitate, numero di visitatori per fascia oraria o giornaliera, aree geografiche di provenienza, ecc.). Altresì vengono utilizzati per controllare il corretto funzionamento dei servizi offerti.
               </li>
               <li>c) dati forniti volontariamente dall'Utente. In fase di fruizione del Portale Pagamenti della Regione Calabria, gli Utenti forniscono volontariamente alcuni dati personali. Tali dati, richiesti come obbligatori o facoltativi, sono trattati esclusivamente ai fini dell'identificazione dell'Utente e per le finalitá del trattamento indicate nella presente informativa. L'invio di posta elettronica agli indirizzi indicati nei differenti canali di accesso del portale e la compilazione dei "format" (maschere) specificamente predisposti, comportano la successiva acquisizione dell'indirizzo di posta e/o dei dati del mittente/utente, necessari per rispondere alle istanze presentate e/o erogare il servizio richiesto.
               </li>
               <li>d) dati relativi alla transazione per il pagamento che si intende effettuare (tra cui numero avviso (IUV), esito, importo, scadenza, causale, data pagamento).
               </li>
            </ul>
            <p>
               Il trattamento dei dati è finalizzato a:  
            </p>
            <ul>
               <li>a) svolgere le attività necessarie per l'utilizzo del servizio di pagamento elettronico PagoPA;
               </li>
               <li>b) svolgere le attività necessarie per l'utilizzo del servizio "Portale dei Pagamenti della Regione Calabria";
               </li>
               <li>c) adempiere agli obblighi connessi all'adesione della Regione Calabria a PagoPA o derivanti dalla legge, regolamenti e normativa comunitaria;
               </li>
               <li>d) consentire a cittadini e imprese di effettuare pagamenti elettronici a favore della Regione Calabria e degli Enti Aderenti, secondo i modelli previsti da PagoPA;
               </li>
               <li>e) perseguire le finalitá amministrative-contabili e adempiere alle esigenze di rendicontazione.
               </li>
            </ul>
         </div>
         <br>
         <h2>  COOKIE E ALTRI SISTEMI DI TRACCIAMENTO</h2>
         <div>
            <p>Per la gestione dei Coockie si rimanda all’apposita pagina del portale https://pagopa.regione.calabria.it/pa. </p>
         </div>
         <br>
         <h2>DATI COMUNICATI DALL'UTENTE</h2>
         <div>
            <p>
               La fruizione di determinati servizi del sito prevede che l’utente fornisca (attraverso specifici moduli) alcuni dati personali. Il conferimento dei dati è facoltativo, ma la mancata comunicazione di parte di essi può comportare l’impossibilità di dare seguito all’erogazione del servizio.
               <br>
               Altresì, l'invio facoltativo, esplicito e volontario di messaggi di posta elettronica, agli indirizzi di contatto riportati nel sito, comporta per sua stessa natura la successiva acquisizione dell’indirizzo del mittente, necessaria per rispondere alla richiesta, nonché degli eventuali altri dati personali inclusi nella comunicazione.
               <br>
               Specifiche informative sono progressivamente rese disponibili o direttamente visualizzate in corrispondenza delle pagine di accesso ai suddetti servizi.
               <br>
               Sia nei moduli di utilizzo dei servizi richiesti sia nei messaggi inviati all’Ente, si invitano in ogni caso gli utenti a non inserire propri dati personali sensibili o dati personali di soggetti terzi, che non siano strettamente necessari.
            </p>
         </div>
         <br>
         <h2>BASE GIURIDICA DEL TRATTAMENTO</h2>
         <div>
            <p>
               I dati personali raccolti durante la consultazione del sito sono trattati da Regione Calabria nell'esecuzione dei propri compiti di interesse pubblico o comunque connessi all'esercizio dei propri pubblici poteri.
            </p>
         </div>
         <br>
         <h2>MODALITA’ DEL TRATTAMENTO</h2>
         <div>
            <p>
               Regione Calabria, nella qualità di Titolare del trattamento, raccoglie direttamente i dati personali degli utenti, attraverso appositi moduli presenti sul sito. Il trattamento dei dati personali è realizzato sia su supporto cartaceo che elettronico, nel rispetto dei principi di liceità e correttezza di cui all’art. 5 del GDPR, operando sempre in modo da garantire la riservatezza e la sicurezza delle informazioni.
               <br>
               Regione Calabria si impegna ad assicurare che le informazioni e i dati raccolti ed utilizzati siano adeguati, pertinenti e limitati, anche nel tempo di conservazione, a quanto necessario rispetto alle finalità di trattamento sopra descritte, e che i suoi dati personali siano trattati in modo da garantite la sicurezza degli stessi, anche attraverso misure tecniche e organizzative adeguate ed efficaci messe in atto dal Titolare, nel rispetto del principio di Accountability (Responsabilizzazione) prescritto dal GDPR, che evitino il rischio di perdita, accesso non autorizzato, uso illecito e diffusione degli stessi.
               <br>
               Il presente trattamento di dati personali non comporta alcuna attivazione di processi decisionali automatizzati.
            </p>
         </div>
         <br>
         <h2>Diritti di reclamo</h2>
         <div>
            <p>
               Gli interessati che ritengono che il trattamento dei dati personali a loro riferiti effettuato attraverso questo sito avvenga in 
               violazione di quanto previsto dal Regolamento hanno il diritto di proporre reclamo al Garante, come previsto dall'art. 77 del 
               Regolamento stesso, o di adire le opportune sedi giudiziarie (art. 79 del Regolamento), con sede in Piazza Venezia n. 11 - Cap. 00187 Roma.
            </p>
         </div>
         <br>
         <h2>DESTINATARI DEI DATI PERSONALI</h2>
         <div>
            <p>
               I dati personali raccolti sono trattati:
            </p>
            <ul>
               <li>da personale dipendente di Regione Calabria, tra cui in particolare il personale del Dipartimento “Transizione Digitale ed Attività Strategiche”;
               </li>
               <li>
                  dal RTI, fornitore dei servizi di sviluppo e gestione operativa della piattaforma informatica del sito, formalmente designato quale responsabile esterno del trattamento, ai sensi dell’art. 28 del GDPR.
               </li>
            </ul>
         </div>
         <br>
         <h2>COMUNICAZIONI A TERZI</h2>
         <div>
            <p>
               I dati personali raccolti nella presente attività non saranno né diffusi né comunicati a terzi, fatti salvi i casi in cui si renda necessario 
               comunicarli a soggetti pubblici legittimati a richiedere tali dati.
            </p>
         </div>
         <br>
         <h2>TRASFERIMENTI EXTRA UE</h2>
         <div>
            <p>
               I dati personali raccolti nella presente attività non saranno né diffusi né trasferiti in paesi extra UE.
            </p>
         </div>
      </mat-card-content>
   </mat-card>
</div>