
<ng-template #helpPage>
  <div>
    <p>Il filtro "Intervallo date" è riferito alla data di pagamento.</p>
    <p>Lo storico delle transazioni comprende sia i pagamenti effettuati con successo sia quelli non completati.
      Per i pagamenti effettuati con successo è possibile scaricare la Ricevuta Telematica.</p>
    <p>Se il pagamento è in stato "Annullato", l'Ente Beneficiario ha annullato la posizione debitoria verso
      l'intestatario e non è necessario procedere al pagamento. Le informazioni sulla posizione debitoria non vengono
      comunque cancellate dal sistema ed è possibile visualizzarne i dettagli.</p>
    <p>Se il pagamento è in stato "Non pagato", c'è stato un tentativo di pagamento che non è andato a buon fine. La
      ricevuta telematica può essere scaricata e avrà importo pari a zero.</p>
    <p>Se il pagamento è in stato "Pagato", la posizione debitoria è stata pagata ed esiste una ricevuta telematica con
      esito positivo. È possibile scaricare la ricevuta telematica.</p>
    <p>Se il pagamento è in stato "Transazione non completata", il pagamento è stato iniziato ma non è pervenuta nessuna
      ricevuta telematica (né con esito positivo né con esito negativo). Per maggiori informazioni contattare l'Ente
      Beneficiario.</p>
    <p>Se il pagamento è in stato "Scaduto", è stata superata la data di scadenza prevista per la posizione debitoria
       e non è più possibile procedere al pagamento. Per regolarizzare la propria posizione debitoria è necessario
       contattare l'Ente Beneficiario.</p>
  </div>
</ng-template>

<ng-template #helpPageTipoDovuto>
  <div>
    <p>Per selezionare un Tipo dovuto è necessario preventivamente aver selezionato un Ente.</p>
  </div>
</ng-template>

<div class="container" role="navigation" aria-label="Pagati container">

  <div fxFlex fxLayout="column" fxLayoutAlign="start center">

    <div class="title-row">
      <h1 class="mat-h1 bold"><fa-icon class="pr-1" *ngIf="titleIcon" [icon]="titleIcon" aria-hidden="true"></fa-icon> {{titleLabel}} <my-pay-help [template]="helpPage"></my-pay-help></h1>
    </div>

    <div class="w100 mypay-search-form">
      <form novalidate #sForm="ngForm" [formGroup]="form" (ngSubmit)="onSubmit()">
        <mat-card #cardSearch class="mat-elevation-z0" fxFlex="1 1 0" fxLayout="column">
          <mat-card-content class="white">
            <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="0px">
              <mat-label id="sr-ente" class="sr-only">Ente</mat-label>
              <mat-form-field fxFlex="100%" fxFlex.gt-sm="calc( 100% - 32em )"  appearance="{{'appearance'|global}}" [floatLabel]="'always'">
                <mat-label aria-hidden="true">Ente</mat-label>
                <input aria-labelledby="sr-ente" type="text" matInput formControlName="ente"
                  [matAutocomplete]="autoEnte" [placeholder]="placeholderEnte">
                <mat-autocomplete #autoEnte="matAutocomplete" [displayWith]="enteDisplayFn">
                  <mat-option *ngFor="let option of enteFilteredOptions | async" [value]="option">
                    <img *ngIf="option.thumbLogoEnte" class="thumbnail-ente" src="{{'data:image/png;base64,'+option.thumbLogoEnte}}" alt="Immagine Ente" aria-hidden="true">
                    {{option.deNomeEnte}}
                  </mat-option>
                </mat-autocomplete>
                <mat-error *ngIf="formErrors['ente']">{{ formErrors.ente }}</mat-error>
              </mat-form-field>

              <!--mat-form-field fxFlex="100%" fxFlex.gt-sm="25%" fxFlex.gt-md="20%" appearance="{{'appearance'|global}}" [floatLabel]="'always'">
                <mat-label>Data da</mat-label>
                <input matInput required formControlName="dateFrom" [matDatepicker]="pickerFrom">
                <mat-datepicker-toggle matSuffix [for]="pickerFrom"></mat-datepicker-toggle>
                <mat-datepicker #pickerFrom></mat-datepicker>
                <mat-error *ngIf="formErrors['dateFrom']">{{ formErrors.dateFrom }}</mat-error>
              </mat-form-field>

              <mat-form-field fxFlex="100%" fxFlex.gt-sm="25%" fxFlex.gt-md="20%" appearance="{{'appearance'|global}}" [floatLabel]="'always'">
                <mat-label>Data a</mat-label>
                <input matInput required formControlName="dateTo" [matDatepicker]="pickerTo">
                <mat-datepicker-toggle matSuffix [for]="pickerTo"></mat-datepicker-toggle>
                <mat-datepicker #pickerTo></mat-datepicker>
                <mat-error *ngIf="formErrors['dateTo']">{{ formErrors.dateTo }}</mat-error>
              </mat-form-field-->

              <mat-form-field fxFlex="100%" fxFlex.gt-sm="17em" appearance="{{'appearance'|global}}" [floatLabel]="'always'">
                <mat-label>Intervallo date</mat-label>
                <mat-date-range-input [rangePicker]="pickerDate">
                  <input matStartDate formControlName="dateFrom" placeholder="data da">
                  <input matEndDate formControlName="dateTo" placeholder="data a">
                </mat-date-range-input>

                <mat-datepicker-toggle matSuffix [for]="pickerDate"></mat-datepicker-toggle>
                <mat-date-range-picker #pickerDate></mat-date-range-picker>
                <mat-error *ngIf="formErrors['dateFrom']">{{ formErrors.dateFrom }}</mat-error>
              </mat-form-field>

              <mat-form-field fxFlex="100%" fxFlex.gt-sm="15em" appearance="{{'appearance'|global}}">
                <mat-label>Stato</mat-label>
                <mat-select required formControlName="state" name="state">
                  <mat-option *ngFor="let state of stateOptions" [value]="state.code">
                    {{state.label}}
                  </mat-option>
                </mat-select>
              </mat-form-field>

            </div>

            <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="0px">
              <mat-label id="sr-dovuto" class="sr-only">Tipo dovuto</mat-label>
              <mat-form-field fxFlex="100%" fxFlex.gt-sm="50%" appearance="{{'appearance'|global}}" [floatLabel]="'always'">
                <mat-label aria-hidden="true">Tipo dovuto</mat-label>
                <input aria-labelledby="sr-dovuto" type="text" matInput formControlName="tipoDovuto"
                  [matAutocomplete]="autoTipoDovuto" [placeholder]="placeholderTipoDovuto">
                <mat-autocomplete #autoTipoDovuto="matAutocomplete" [displayWith]="tipoDovutoDisplayFn">
                  <mat-option *ngFor="let optionTipoDovuto of tipoDovutoFilteredOptions | async" [value]="optionTipoDovuto">
                    {{optionTipoDovuto.deTipo}}
                  </mat-option>
                </mat-autocomplete>
                <mat-error *ngIf="formErrors['tipoDovuto']">{{ formErrors.tipoDovuto }}</mat-error>
              </mat-form-field>

              <mat-label id="sr-causale" class="sr-only">Causale</mat-label>
              <mat-form-field fxFlex="100%" fxFlex.gt-sm="50%" appearance="{{'appearance'|global}}" [floatLabel]="'always'">
                <mat-label aria-hidden="true">Causale</mat-label>
                <input aria-labelledby="sr-causale" matInput formControlName="causale">
                <mat-error *ngIf="formErrors['causale']">{{ formErrors.causale }}</mat-error>
              </mat-form-field>
            </div>
          </mat-card-content>
          <mat-card-actions>
            <div fxLayout="row wrap" fxLayoutAlign="end center" fxLayoutGap="0px" class="mx-2 mb-3 mat-button-bar">
              <button type="button" mat-flat-button (click)="onReset()" color="primary">Reset</button>
              <button type="submit" mat-flat-button [disabled]="form.invalid || blockingError" color="accent">Cerca</button>
            </div>
          </mat-card-actions>
        </mat-card>
      </form>
    </div>

    <p *ngIf="hasSearched && tableData?.length==0">Nessun dato trovato con i criteri di ricerca impostati.</p>
    <my-pay-table-cittadino [tableData]="tableData" [tableColumns]="tableColumns" [parentRef]="this" [hasDetail]="true">
    </my-pay-table-cittadino>

  </div>

</div>