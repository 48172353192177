<header role="banner" aria-label="Barra di navigazione">
 
  
  <mat-toolbar role="navigation" color="primary" aria-label="Toolbar principale">

   <!-- Toolbar utilizzata per gli avvisi 
   <mat-toolbar [class]="classHeader" color="primary" class="third-level mypay-toolbar first-third-level" role="heading" aria-level="2" aria-label=""
      style="background-color: yellow;color: #990000;font-weight: bold;">
    <mat-toolbar-row class="centered-row">
      <fa-icon [icon]="iconExclamationCircle"></fa-icon>
      <p style="font-size: 15px;padding: 10px 0px 0px 10px;">Si informa che oggi 28 marzo 2024 dalle ore 13:00 alle ore 15:00 il servizio non sarà disponibile per un intervento di manutenzione straordinaria.</p>
    </mat-toolbar-row>
  </mat-toolbar>
  -->

  <mat-toolbar-row>
    <button mat-icon-button aria-label="Apri Menù" (click)="toggleSidenav()" focusvisible="true"><fa-icon [icon]="iconBars" size="2x" class="active" alt="Menu"></fa-icon></button>
    <img class="logo" src="assets/images/pagolapa-white.png" alt="Logo pagoPA" height="50">
    <img class="logo" src="assets/images/logo-MyPay-white.png" alt="Logo MyPay">
    <span class="flex-spacer" aria-hidden="true"></span>
    <button mat-button *ngFor="let item of menuService.getMenuFirstLevel(logged,'center')"
        [fxShow]="item.active || item.dontHide"
        [class]="item.active?'active-first':null" fxShow.gt-md
        (click)="menuService.onClickFirstLevel(item)">
          <span class="side-user-icon">
            <img *ngIf="item.hasImgIcon()" alt="menu-item-icon" [src]="item.icon" class="side-user-icon-img" />
            <fa-icon *ngIf="item.hasFAIcon()" [icon]="item.icon" size="lg"></fa-icon>
          </span>
          <span>{{item.getHeaderLabel()}}</span>
    </button>
    <span class="flex-spacer" aria-hidden="true" ></span>

    <button id="buttonLoginOperatore" aria-labelledby="spanAccessoOperatore" class="button-login-operatore" mat-button (click)="openOperatore()">
      <fa-icon fxHide="false" fxHide.gt-sm [icon]="iconSignIn" size="lg" matTooltip="Accesso Operatore"></fa-icon>
      <span id="spanAccessoOperatore" fxShow="false" fxShow.gt-sm>Accesso Operatore</span>
    </button>

    <button id="buttonLoginAreaPersonale" aria-labelledby="spanAreaPersonale" class="button-login" mat-button *ngIf="!logged" (click)="openLoginForm()">
      <fa-icon fxHide="false" fxHide.gt-sm [icon]="iconSignIn" size="lg" matTooltip="Accedi all'area personale"></fa-icon>
      <span id="spanAreaPersonale" fxShow="false" fxShow.gt-sm>Accedi all'area personale</span>
    </button>

    <button mat-button *ngFor="let item of menuService.getMenuFirstLevel(logged,'right')"
        [fxShow]="item.active || item.dontHide" fxShow.gt-md
        [class]="item.active?'active-first':null"
        (click)="menuService.onClickFirstLevel(item)"
        [attr.aria-labelledby]="item.getId()" role="link">
          <span class="side-user-icon">
            <img *ngIf="item.hasImgIcon()" alt="menu-item-icon" [src]="item.icon" class="side-user-icon-img"/>
            <fa-icon *ngIf="item.hasFAIcon()" [icon]="item.icon" size="lg" alt="{{item}}"></fa-icon>
          </span>
          <span id="{{item.getId()}}" class="sr-only">{{item.getAriaLabel()}}</span>
          <span aria-hidden="true">{{item.getHeaderLabel()}}</span>
        </button>

    <button mat-button aria-label="Esci" matTooltip="Esci" *ngIf="logged" (click)="logout()"><fa-icon [icon]="itemSignOut" size="lg" title="Esci"></fa-icon></button>

  </mat-toolbar-row>
</mat-toolbar>
<mat-toolbar [class]="classHeader" *ngIf="secondLevelMenu?.length>0" color="accent" class="second-level mypay-toolbar" role="heading" aria-level="2" aria-label="">
  <mat-toolbar-row>
    <span class="flex-spacer" aria-hidden="true"></span>
    <button mat-button *ngFor="let item of secondLevelMenu"
        class="mypay-toolbar-item"
        [href]="item.external?item.url:null"
        [routerLink]="item.external?null:item.url"
        (click)="myPayBreadcrumbsService.resetBreadcrumbsIfPageChange(item.external?null:item.url)"
        routerLinkActive="active-second"
        [attr.aria-label]="item.labelHeader">
          <span class="side-user-icon">
            <img *ngIf="item.hasImgIcon()" alt="menu-item-icon" [src]="item.icon" class="side-user-icon-img"/>
            <fa-icon *ngIf="item.hasFAIcon() && !item.hasFABadge()" [icon]="item.icon" size="lg"></fa-icon>
            <fa-layers class="badge-fa-layers" *ngIf="item.hasFAIcon() && item.hasFABadge()" [fixedWidth]="true">
              <fa-icon [icon] = "item.icon" size="lg"></fa-icon>
              <fa-layers-counter [content]="item.iconBadgeFun()" ></fa-layers-counter>
            </fa-layers>
          </span>
          <span>{{item.labelHeader}}</span>
    </button>
    <button mat-icon-button aria-label="Apri toolbar" class="toolbar-trigger" (click)="onClickButtonHeader()"><fa-icon [icon]="iconHeader" size="lg"></fa-icon></button>
    <span class="flex-spacer" aria-hidden="true"></span>
  </mat-toolbar-row>
</mat-toolbar>

<!-- Toolbar terzo livello con link accesso FE Operatore -->
<mat-toolbar [class]="classHeader" color="primary" class="third-level mypay-toolbar first-third-level" role="heading" aria-level="2" aria-label="">
  <mat-toolbar-row class="centered-row">
    <fa-icon [icon]="iconInfoCircle"></fa-icon>
    <p style="font-size: 15px;padding: 10px 0px 0px 10px;">Per qualsiasi informazione inerente agli accertamenti TASSA AUTOMOBILISTICA pervenuti dalla Regione Calabria si invita ad accedere e seguire le istruzioni fornite al seguente <a role="link" href="https://tributi.regione.calabria.it/web/guest/tassa-auto" class="link-text-toolbar-third-level"> >> LINK <<</a></p>
  </mat-toolbar-row>
</mat-toolbar>

<!-- Toolbar terzo livello con link pagina adesione -->
<mat-toolbar [class]="classHeader" color="primary" class="third-level mypay-toolbar" role="heading" aria-level="2" aria-label="">
  <mat-toolbar-row class="centered-row">
    <fa-icon [icon]="iconInfoCircle"></fa-icon>
    <p style="font-size: 15px;padding: 10px 0px 0px 10px;">
    <a routerLink="/info/adesione" class="link-text-toolbar-third-level">Per gli Enti che intendono aderire e per assistenza al cittadino per i pagamenti telematici cliccare QUI</a>
  </p>
  </mat-toolbar-row>
</mat-toolbar>

</header>